<template>
  <div class="table-text-header">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppTableTextHeader'
}
</script>

<style scoped>
.table-text-header {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 150%;
  color: #999;
}

@media screen and (min-width: 576px) {
  .table-text-header {
    display: table-cell;
    padding-bottom: 15px;
    color: #555;
  }
}

@media screen and (min-width: 768px) {
  .table-text-header {
    font-size: 15px;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 992px) {
  .table-text-header {
    display: none;
  }
}
</style>