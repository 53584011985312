<template>
  <div class="table">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppTable'
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.table {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .table {
    display: table;
    border-collapse: collapse;
  }
}
</style>