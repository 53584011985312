<template>
  <div class="table-row">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppTableRow'
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.table-row {
  position: relative;
  padding: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}

@media screen and (min-width: 576px) {
  .table-row {
    padding: 25px 185px 10px 20px;
  }
}

@media screen and (min-width: 768px) {
  .table-row {
    padding: 25px 195px 5px 25px;
  }
}

@media screen and (min-width: 992px) {
  .table-row {
    display: table-row;
  }
}
</style>