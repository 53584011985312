<template>
  <div class="modal">
    <container class="modal__window">
      <div class="modal__caption">
        {{ caption }}
      </div>
      <p class="modal__message">
        {{ message }}
      </p>
      <div class="modal__button-group">
        <app-button class="modal__button" button-style="button_primary" @click="confirm(data)">
          Подтвердить
        </app-button>
        <app-button class="modal__button" button-style="button_cancel" @click="cancel">
          Отменить
        </app-button>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/layouts/Container'
import AppButton from '@/components/AppButton'

export default {
  name: 'ModalForm',
  components: {
    AppButton,
    Container
  },
  props: {
    data: Object,
    caption: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    confirm: {
      type: Function,
      required: true
    },
    cancel: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal__window {
  width: 280px;
  padding: 30px 20px;
  border-radius: $border-radius;
}

.modal__caption {
  margin-bottom: 15px;
  font-family: "Inter Bold", "sans-serif";
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}

.modal__message {
  margin: 0 0 25px;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
}

.modal__button-group {
  display: flex;
  gap: 20px;
}

.modal__button {
  flex-basis: 100%;
}

@media screen and (min-width: 576px) {
  .modal__window {
    width: 480px;
    padding: 60px;
  }

  .modal__caption {
    margin-bottom: 25px;
    font-size: 26px;
  }

  .modal__message {
    margin: 0 0 40px;
    font-size: 18px;
  }

  .modal__button-group {
    gap: 30px;
  }
}
</style>