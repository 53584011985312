<template>
  <div class="paginator" v-if="paginator.total_pages > 1">
    <div class="paginator__container">
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: paginator.current_page - 1
            }
          }"
          v-if="paginator.current_page !== 1"
          class="paginator__item paginator__arrow paginator__arrow-left"></router-link>
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: 1
            }
          }"
          :class="{ 'paginator__item_active' : paginator.current_page === 1 }"
          class="paginator__item">
        1
      </router-link>
      <div class="paginator__item" v-if="paginator.current_page - 1 > 2">
        ...
      </div>
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: paginator.current_page - 1
            }
          }"
          v-if="paginator.current_page - 1 > 1"
          class="paginator__item">
        {{ paginator.current_page - 1 }}
      </router-link>
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: paginator.current_page
            }
          }"
          v-if="paginator.current_page !== 1 && paginator.current_page !== paginator.total_pages"
          class="paginator__item paginator__item_active">
        {{ paginator.current_page }}
      </router-link>
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: paginator.current_page + 1
            }
          }"
          v-if="paginator.total_pages - paginator.current_page > 1"
          class="paginator__item">
        {{ paginator.current_page + 1 }}
      </router-link>
      <div class="paginator__item" v-if="paginator.total_pages - paginator.current_page > 2">
        ...
      </div>
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: paginator.total_pages
            }
          }"
          :class="{ 'paginator__item_active' : paginator.current_page === paginator.total_pages }"
          class="paginator__item">
        {{ paginator.total_pages }}
      </router-link>
      <router-link
          :to="{
            name: urlName,
            query: {
              ...$route.query,
              page: paginator.current_page + 1
            }
          }"
          v-if="paginator.current_page !== paginator.total_pages"
          class="paginator__item paginator__arrow paginator__arrow-right"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTablePaginator',
  props: {
    urlName: {
      type: String,
      required: true
    },
    paginator: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.paginator {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid $separator-color;
}

.paginator__container {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 50px;
}

.paginator__item {
  font-size: 14px;
  color: $primary-links-color-inactive;
  cursor: pointer;

  &_active {
    position: relative;
    color: $primary-links-color;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -18px;
      border-bottom: 1px solid $primary-links-color;
      transform: scaleX(2);
    }
  }
}

.paginator__arrow {
  position: relative;
  width: 6px;
  height: 10px;

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 7px;
    border-bottom: 1px solid $primary-links-color-inactive;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.paginator__arrow-left {
  &::before {
    top: 7px;
  }

  &::after {
    top: 2.5px;
  }
}

.paginator__arrow-right {
  &::before {
    top: 2.5px;
  }

  &::after {
    top: 7px;
  }
}

@media screen and (min-width: 576px) {
  .paginator {
    justify-content: flex-start;
    padding: 0 20px;
  }

  .paginator__container {
    height: 70px;
  }

  .paginator__item {
    font-size: 15px;
  }

  .paginator__item_active::after {
    bottom: -27.5px;
  }
}

@media screen and (min-width: 992px) {
  .paginator {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  .paginator {
    padding: 0 40px;
  }
}
</style>