<template>
  <router-link :to="path" class="table-link" :class="`table-link_${type}`">
    <slot/>
  </router-link>
</template>

<script>
export default {
  name: 'AppTableLink',
  props: {
    type: {
      type: String,
      required: true
    },
    path: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.table-link {
  display: inline-block;
  font-size: 12px;
  line-height: 150%;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.table-link_primary {
  position: relative;
  padding-right: 10px;
  color: $secondary-links-color;

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    width: 6px;
    border-bottom: 1px solid $secondary-links-color;
  }

  &::before {
    top: 7.5px;
    transform: rotate(45deg);
  }

  &::after {
    top: 11px;
    transform: rotate(-45deg);
  }
}

.table-link_danger {
  color: $error-text-color;
}

@media screen and (min-width: 576px) {
  .table-link {
    font-size: 14px;
  }

  .table-link_primary {
    padding-right: 12px;
  }

  .table-link_primary::before, .table-link_primary::after {
    width: 7px;
  }

  .table-link_primary::before {
    top: 8.5px;
  }

  .table-link_primary::after {
    top: 13px;
  }
}

@media screen and (min-width: 768px) {
  .table-link {
    font-size: 16px;
  }

  .table-link_primary {
    padding-right: 14px;
  }

  .table-link_primary::before, .table-link_primary::after {
    width: 8px;
  }

  .table-link_primary::before {
    top: 10px;
  }

  .table-link_primary::after {
    top: 15px;
  }
}

@media screen and (min-width: 992px) {
  .table-link:not(:last-child) {
    margin-right: 0;
  }

  .table-link::before, .table-link::after {
    left: 95px;
  }
}
</style>