<template>
  <div class="table-column">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppTableColumn'
}
</script>

<style scoped>
.table-column {
  margin-bottom: 15px;
}

@media screen and (min-width: 576px) {
  .table-column {
    display: table-row;
  }
}

@media screen and (min-width: 992px) {
  .table-column {
    display: table-cell;
    margin-bottom: 0;
    padding: 25px 10px;
    vertical-align: top;
  }

  .table-column:first-child {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .table-column:first-child {
    padding-left: 40px;
  }
}
</style>