<template>
  <div class="table-text">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppTableText'
}
</script>

<style scoped>
.table-text {
  font-size: 14px;
  line-height: 150%;
}

@media screen and (min-width: 576px) {
  .table-text {
    display: table-cell;
    padding: 0 0 15px 20px;
  }
}

@media screen and (min-width: 768px) {
  .table-text {
    font-size: 16px;
    padding: 0 0 20px 30px;
  }
}

@media screen and (min-width: 992px) {
  .table-text {
    padding: 0;
  }
}
</style>