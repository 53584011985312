<template>
  <div class="table-header">
    <div class="table-header__item" v-for="header in headers" :key="header">
      <div
          class="table__caption"
          :class="{
            'table__caption_selected' : selectedHeader !== null && (selectedHeader === header.field || selectedHeader.substr(1) === header.field),
            'table__caption_selected-up' : selectedHeader !== null && selectedHeader === header.field && ! selectedHeader.startsWith('-'),
            'table__caption_selected-down' : selectedHeader !== null && selectedHeader.substr(1) === header.field && selectedHeader.startsWith('-')
          }"
          @click="emitSort(header)"
      >
        {{ header.display }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTableHeader',
  props: {
    headers: {
      type: Array,
      required: true
    }
  },
  emits: ['sort'],
  data() {
    return {
      selectedHeader: null
    }
  },
  methods: {
    emitSort(header) {
      if (header.field === null) {
        return
      }

      if (
        this.selectedHeader !== null
        && this.selectedHeader.startsWith('-')
        && this.selectedHeader.substr(1) === header.field) {
        this.selectedHeader = null
      } else if (this.selectedHeader === header.field) {
        this.selectedHeader = '-' + header.field
      } else {
        this.selectedHeader = header.field
      }

      this.$emit('sort', this.selectedHeader)
    }
  },
  created() {
    this.selectedHeader = this.$route.query.sort || null
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.table-header {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 30px;
  padding: 15px 20px;
  border-bottom: 1px solid $separator-color;
}

.table__caption {
  display: inline-block;
  font-size: 12px;
  color: $caption-text-color;
  cursor: pointer;

  &_selected {
    position: relative;
    padding-right: 12px;
    font-family: "Inter Medium", "sans-serif";
    font-weight: 500;
    color: $label-text-color;

    &::before, &::after {
      content: "";
      position: absolute;
      width: 5px;
      top: 7px;
      border-bottom: 1px solid $label-text-color;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &-up {
      &::before {
        right: 0;
      }

      &::after {
        right: 3px;
      }
    }

    &-down {
      &::before {
        right: 3px;
      }

      &::after {
        right: 0;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .table-header {
    padding: 25px 20px;
  }

  .table__caption {
    font-size: 14px;
  }

  .table__caption_selected::before, .table__caption_selected::after {
    top: 8px;
  }
}

@media screen and (min-width: 768px) {
  .table__caption {
    font-size: 15px;
  }

  .table__caption_selected::before, .table__caption_selected::after {
    top: 9px;
  }
}

@media screen and (min-width: 992px) {
  .table-header {
    display: table-row;
    padding: 0;
  }

  .table-header__item {
    display: table-cell;
    padding: 25px 10px;
  }

  .table-header__item:first-child {
    padding-left: 40px;
  }

  .table__caption, .table__caption_selected {
    color: #555;
  }
}
</style>