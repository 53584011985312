<template>
  <div class="table-button-column">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppTableButtonColumn'
}
</script>

<style scoped>
.table-button-column {
  margin-top: 20px;
}

@media screen and (min-width: 576px) {
  .table-button-column {
    position: absolute;
    bottom: 25px;
    right: 20px;
    margin-top: 20px;
  }
}

@media screen and (min-width: 992px) {
  .table-button-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 25px;
    position: static;
    margin-top: 0;
    padding: 25px 30px 25px 0;
  }
}

@media screen and (min-width: 1200px) {
  .table-button-column {
    flex-direction: row;
    gap: 40px;
    padding: 25px 40px 25px 0;
  }
}
</style>